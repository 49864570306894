const SolidaritaetMitKristinaHaenelContent = {
    german: {
        date: "5. Februar 2021",
        arrow: "BLOG << ",
        headline: "Solidarität mit Kristina Hänel – My body, my choice gilt in Deutschland immer noch nicht.",
        firstFirst: "Im Januar 2021 hat das Oberlandesgericht in Frankfurt am Main die Ärztin ",
        firstLink: "Kristina Hänel",
        firstSecond: " zur Strafe nach §219a des Strafgesetzbuches (“Werbung für den Abbruch der Schwangerschaft”) verurteilt.",
        second: "Zu Unrecht, finden wir als feministisches Kollektiv, das sich für die Selbstbestimmung aller Menschen, schwangeren und nichtschwangeren, einsetzen möchte. Ärzt:innen, die Abbrüche anbieten, muss es erlaubt sein Informationen dazu frei zugänglich zur Verfügung zu stellen! Wir unterstützen die Entscheidung von Hänel, eine Verfassungsberichtbeschwerde einzureichen. Hier veröffentlichen wir Informationen zu Schwangerschaftsabbrüchen.",
        third: "Wir solidarisieren uns mit den Ärzt:innen Kristina Hänel, Bettina Gaber, Nora Szász und allen weiteren Ärzt:innen, die über Schwangerschaftsabbrüche informieren, schwangere Menschen unterstützen, begleiten und/oder selber Schwangerschaftsabbrüche durchführen. Was sie nicht dürfen, machen nun wir gemeinsam mit anderen Feminist:innen, denn: “Wenn auch nur ein paar von euch in die Lücke springen, wenn auch nur ein paar von euch sachliche Informationen ins Netz stellen – dann braucht es meine nicht mehr.” ",
        thirdLink: "sagte Kristina Hänel nach dem Urteil des Oberlandesgerichts in einem Interview mit der taz.",
        fourth: "Hier findest du die Informationen, die die Ärztin Kristina Hänel bis jetzt nicht auf der Webseite ihrer Praxis veröffentlichen darf in deutscher Sprache als pdf.",
        fifth: "Information on terminations of pregnancies in Germany in English language as pdf.",
        sixth: "Kürtaj bilgileri – Türkçe, pdf.",
        seventhLink: "Eine unabhängige Beratungsstelle, an die du dich bei Fragen, Unsicherheiten oder für ein (vor einem Abbruch gesetzlich erforderliches) Beratungsgespräch wenden kannst, ist Profamilia",
        seventh: ". Auf der Homepage finden sich ebenfalls Informationen zu Möglichkeiten und Formen des Abbruchs, sowie zu Verhütung und Familieplanung (keine pdf).",
        big: "Achtung!",
        eighth: " Pro Familia ist nicht zu verwechseln mit dem Portal „pro femina“, das vermeintlich „neutrale“ Telefonberatung anbietet und schwangere Personen von Abbrüchen abhalten will. Es ist mit der christlich-fundamentalistischen „pro-life“ Szene verknüpft.",
        ninthLinkFirst: "Auf dieser Homepage findest du Informationen zu den Themen Schwangerschaftsabbruch und Rechtslage, Beratung und Verhütung",
        ninthFirst: ". Die Informationen gibt es auch in ",
        ninthLinkSecond: "Leichter Sprache",
        ninthSecond: " und in ",
        ninthLinkThird: "deutscher Gebärdensprache (DSG).",
        tenth: "Das Bündnis für körperliche Selbstbestimmung Frankfurt hat außerdem eine Comic-Broschüre mit dem Titel „How to Abtreibung in Deutschland“ herausgebracht, das Informationen bereit stellt und politischen Kontext bietet. ",
        tenthLink: "Die Bürschüre kann hier bestellt oder ausgedruckt werden.",
        eleventh: "Eine selbstbestimmte Entscheidung für oder gegen einen Schwangerschaftsabbruch kann erst getroffen werden, wenn ausreichend sachliche Informationen für schwangere Menschen zugänglich gemacht werden. Diese bereitzustellen kann nicht nur die Aufgabe der Zivilgesellschaft sein, sondern muss zu den ärztlichen Pflichten zählen! Und damit die Ärzt:innen die Ratsuchenden auch ausreichend beraten und begleiten können, muss das Thema der Schwangerschaftsabbrüche kompromisslos Teil der Ausbildungsinhalte der universitärer Lehre werden. ",
        eleventhLink: "Dafür setzen sich unter anderem die kritischen Mediziner:innen der Charité in Berlin ein.",
        secondHeadlineFirst:"Wir können es nicht oft genug betonen: My Body, My Choice!",
        secondHeadlineSecond:"Zum Weiterlesen:",
        twelfth: "„Selbstbestimmt zu handeln“ ist, wenn es um den eigenen Körper geht, für manche nicht nur eine Frage der freien Wahl, sondern sie ist auch abhängig von gesellschaftlichen (Fremd-)Positionierungen und eng verstrickt mit unserer Geschichte. Wer steht in den Debatten um reproduktive Gerechtigkeit im Mittelpunkt und wer wird übersehen? Und warum? Schwarze, queere und be_hinderte Feminist:innen machen auf Verengungen der pro-choice-Debatte aufmerksam.",
        thirteenth: "Wer sich in diese  Debatten tiefer einlesen möchte, für den stellen wir in diesem Blogbeitrag einen kleinen Überblick bereit. “No justice, no choice” – pro-choice intersektional weiterdenken."

    },
    english: {
        date: "February 5, 2021",
        arrow: "BLOG << ",
        headline: "Solidarität mit Kristina Hänel – My body, my choice gilt in Deutschland immer noch nicht.",
        firstFirst: "Im Januar 2021 hat das Oberlandesgericht in Frankfurt am Main die Ärztin ",
        firstLink: "Kristina Hänel",
        firstSecond: " zur Strafe nach §219a des Strafgesetzbuches (“Werbung für den Abbruch der Schwangerschaft”) verurteilt.",
        second: "Zu Unrecht, finden wir als feministisches Kollektiv, das sich für die Selbstbestimmung aller Menschen, schwangeren und nichtschwangeren, einsetzen möchte. Ärzt:innen, die Abbrüche anbieten, muss es erlaubt sein Informationen dazu frei zugänglich zur Verfügung zu stellen! Wir unterstützen die Entscheidung von Hänel, eine Verfassungsberichtbeschwerde einzureichen. Hier veröffentlichen wir Informationen zu Schwangerschaftsabbrüchen.",
        third: "Wir solidarisieren uns mit den Ärzt:innen Kristina Hänel, Bettina Gaber, Nora Szász und allen weiteren Ärzt:innen, die über Schwangerschaftsabbrüche informieren, schwangere Menschen unterstützen, begleiten und/oder selber Schwangerschaftsabbrüche durchführen. Was sie nicht dürfen, machen nun wir gemeinsam mit anderen Feminist:innen, denn: “Wenn auch nur ein paar von euch in die Lücke springen, wenn auch nur ein paar von euch sachliche Informationen ins Netz stellen – dann braucht es meine nicht mehr.” ",
        thirdLink: "sagte Kristina Hänel nach dem Urteil des Oberlandesgerichts in einem Interview mit der taz.",
        fourth: "Hier findest du die Informationen, die die Ärztin Kristina Hänel bis jetzt nicht auf der Webseite ihrer Praxis veröffentlichen darf in deutscher Sprache als pdf.",
        fifth: "Information on terminations of pregnancies in Germany in English language as pdf.",
        sixth: "Kürtaj bilgileri – Türkçe, pdf.",
        seventhLink: "Eine unabhängige Beratungsstelle, an die du dich bei Fragen, Unsicherheiten oder für ein (vor einem Abbruch gesetzlich erforderliches) Beratungsgespräch wenden kannst, ist Profamilia",
        seventh: ". Auf der Homepage finden sich ebenfalls Informationen zu Möglichkeiten und Formen des Abbruchs, sowie zu Verhütung und Familieplanung (keine pdf).",
        big: "Achtung!",
        eighth: " Pro Familia ist nicht zu verwechseln mit dem Portal „pro femina“, das vermeintlich „neutrale“ Telefonberatung anbietet und schwangere Personen von Abbrüchen abhalten will. Es ist mit der christlich-fundamentalistischen „pro-life“ Szene verknüpft.",
        ninthLinkFirst: "Auf dieser Homepage findest du Informationen zu den Themen Schwangerschaftsabbruch und Rechtslage, Beratung und Verhütung",
        ninthFirst: ". Die Informationen gibt es auch in ",
        ninthLinkSecond: "Leichter Sprache",
        ninthSecond: " und in ",
        ninthLinkThird: "deutscher Gebärdensprache (DSG).",
        tenth: "Das Bündnis für körperliche Selbstbestimmung Frankfurt hat außerdem eine Comic-Broschüre mit dem Titel „How to Abtreibung in Deutschland“ herausgebracht, das Informationen bereit stellt und politischen Kontext bietet. ",
        tenthLink: "Die Bürschüre kann hier bestellt oder ausgedruckt werden.",
        eleventh: "Eine selbstbestimmte Entscheidung für oder gegen einen Schwangerschaftsabbruch kann erst getroffen werden, wenn ausreichend sachliche Informationen für schwangere Menschen zugänglich gemacht werden. Diese bereitzustellen kann nicht nur die Aufgabe der Zivilgesellschaft sein, sondern muss zu den ärztlichen Pflichten zählen! Und damit die Ärzt:innen die Ratsuchenden auch ausreichend beraten und begleiten können, muss das Thema der Schwangerschaftsabbrüche kompromisslos Teil der Ausbildungsinhalte der universitärer Lehre werden. ",
        eleventhLink: "Dafür setzen sich unter anderem die kritischen Mediziner:innen der Charité in Berlin ein.",
        secondHeadlineFirst:"Wir können es nicht oft genug betonen: My Body, My Choice!",
        secondHeadlineSecond:"Zum Weiterlesen:",
        twelfth: "„Selbstbestimmt zu handeln“ ist, wenn es um den eigenen Körper geht, für manche nicht nur eine Frage der freien Wahl, sondern sie ist auch abhängig von gesellschaftlichen (Fremd-)Positionierungen und eng verstrickt mit unserer Geschichte. Wer steht in den Debatten um reproduktive Gerechtigkeit im Mittelpunkt und wer wird übersehen? Und warum? Schwarze, queere und be_hinderte Feminist:innen machen auf Verengungen der pro-choice-Debatte aufmerksam.",
        thirteenth: "Wer sich in diese  Debatten tiefer einlesen möchte, für den stellen wir in diesem Blogbeitrag einen kleinen Überblick bereit. “No justice, no choice” – pro-choice intersektional weiterdenken."

    }
}
export {SolidaritaetMitKristinaHaenelContent}