import React, { useContext } from "react"
import { Link } from "gatsby"
import Template from "../../components/template"
import "../../styles/blog/blogpost.css"
import { SolidaritaetMitKristinaHaenelContent } from "../../content/blog/solidaritaet-mit-Kristina-Haenel"
import { SettingsContext } from "../../contexts/settings"

const SolidaritaetMitKristinaHaenelPage = () => {
    const [settings, ] = useContext(SettingsContext)
    const content = SolidaritaetMitKristinaHaenelContent[settings.language]
    return (
        <Template currentPage="blog">
            <section>
            <div>
                <h1>
                    <Link to ="/blog#solidaritaet-mit-Kristina-haenel" class="back-arrow">{content.arrow}</Link>
                    {content.headline}
                </h1>
                <p>{content.date}</p>
                <p>
                    {content.firstFirst}
                    <a className="link" href="https://www.kristinahaenel.de/page_kristina_haenel.php" target="_blank" rel="noopener noreferrer">{content.firstLink}</a>
                    {content.firstSecond}
                </p>
                <p>
                    {content.second}
                </p>
                <p>
                    {content.third}
                    <a className="link" href="https://taz.de/Kristina-Haenel-ueber-ihr-219a-Urteil/!5745523/" target="_blank" rel="noopener noreferrer">{content.thirdLink}</a>
                </p>
                <p>
                    <a className="link" href="https://solidaritaetfuerkristinahaenel.files.wordpress.com/2021/01/schwangerschaftsabbruch.pdf" target="_blank" rel="noopener noreferrer">{content.fourth}</a>
                </p>
                <p>
                    <a className="link" href="https://solidaritaetfuerkristinahaenel.files.wordpress.com/2021/01/schwangerschaftsabbruch_englisch.pdf" target="_blank" rel="noopener noreferrer">{content.fifth}</a>
                </p>
                <p>
                    <a className="link" href="https://solidaritaetfuerkristinahaenel.files.wordpress.com/2021/01/schwangerschaftsabbruch_tuerkisch.pdf" target="_blank" rel="noopener noreferrer">{content.sixth}</a>
                </p>
                <p>
                    <a className="link" href="https://www.profamilia.de/themen/schwangerschaftsabbruch." target="_blank" rel="noopener noreferrer">{content.seventhLink}</a>
                    {content.seventh}
                </p>
                <p>
                    <b>{content.big}</b>
                    {content.eighth}
                </p>
                <p>
                    <a className="link" href="https://www.familienplanung.de/schwangerschaftskonflikt/schwangerschaftsabbruch/" target="_blank" rel="noopener noreferrer">{content.ninthLinkFirst}</a>
                    {content.ninthFirst}
                    <a className="link" href="https://www.familienplanung.de/leichte-sprache/" target="_blank" rel="noopener noreferrer">{content.ninthLinkSecond}</a>
                    {content.ninthSecond}
                    <a className="link" href="https://www.familienplanung.de/gebaerdensprache/" target="_blank" rel="noopener noreferrer">{content.ninthLinkThird}</a>
                </p>
                <p>
                    {content.tenth}
                    <a className="link" href="https://bfksffm.wordpress.com/mediathek/broschuere-how-to-abtreibung-in-deutschland-comic/" target="_blank" rel="noopener noreferrer">{content.tenthLink}</a>
                </p>
                <p>
                    {content.eleventh}
                    <a className="link" href="https://msfcberlin.com/schwangerschaftsabbruch/" target="_blank" rel="noopener noreferrer">{content.eleventhLink}</a>
                </p>
                <h4>{content.secondHeadlineFirst}</h4>
                <h4>{content.secondHeadlineSecond}</h4>
                <p>
                    {content.twelfth}
                </p>
                <p className="end-of-site">
                    <a className="link" href="/blog/no-justice-no-choice" target="_blank" rel="noopener noreferrer">
                        {content.thirteenth}
                    </a>
                    
                </p>
            </div>    
            </section>
        </Template >
    )
}

export default SolidaritaetMitKristinaHaenelPage